import {
    ADMIN_LOADING,
    ADMIN_LOADED,
    ADMIN_LOAD_ERROR,
    ADMIN_REGISTERING,
    ADMIN_REGISTERED,
    ADMIN_REGISTER_ERROR,
    ADMIN_LOGINING,
    ADMIN_LOGINED,
    ADMIN_LOGIN_ERROR,
    ADMIN_PASSWORD_RESETING,
    ADMIN_PASSWORD_RESETED,
    ADMIN_PASSWORD_RESET_ERROR,
    ADMIN_TOKEN_REFRESHING,
    ADMIN_TOKEN_REFRESHED,
    ADMIN_TOKEN_REFRESH_ERROR,
    ADMIN_LOGOUT,
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: JSON.parse(localStorage.getItem('isAuth')),
    isLoading: false,
    user: null,
    err: null,
    isAdminLoading: false,
    isAdminLoaded: false,
    isAdminRegistering: false,
    isAdminRegistered: false,
    isAdminLogin: false,
    isAdminLogined: false,
    isAdminPasswordReseting: false,
    isAdminPasswordReseted: false,
    isAdminTokenRefreshing: false,
    isAdminTokenRefreshed: false,
    name: localStorage.getItem('username'),
};

export default function (state = initialState, action) {
    switch (action.type) {

        case ADMIN_LOADING:
            return {
                ...state,
                isAdminLoading: true,
                isAdminLoaded: false,
                err: null,
            };

        case ADMIN_LOADED:
            return {
                ...state,
                isAdminLoading: false,
                isAdminLoaded: true,
                user: action.payload,
            };

        case ADMIN_LOAD_ERROR:
        case ADMIN_PASSWORD_RESET_ERROR:
            return {
                ...state,
                err: action.payload,
            };

        case ADMIN_REGISTERING:
            return {
                ...state,
                isAdminRegistering: true,
                isAdminRegistered: false,
                err: null,
            };

        case ADMIN_REGISTERED:
            return {
                ...state,
                isAdminRegistering: false,
                isAdminRegistered: true,
            }

        case ADMIN_REGISTER_ERROR:
        case ADMIN_LOGIN_ERROR:
        case ADMIN_LOGOUT:
        case ADMIN_TOKEN_REFRESH_ERROR:
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('isAuth');
            return {
                ...state,
                isAdminRegistering: false,
                isAdminRegistered: false,
                token: null,
                isAuthenticated: false,
                user: null,
                err: action.payload,
            };

        case ADMIN_LOGINING:
            return {
                ...state,
                isAdminLogin: true,
                isAdminLogined: false,
                isAdminRegistered: false,
                err: null,
            };

        case ADMIN_LOGINED:
            localStorage.setItem('token', action.payload.token.accessToken);
            localStorage.setItem('username', action.payload.name);
            localStorage.setItem('isAuth', true);
            return {
                ...state,
                isAdminLogin: false,
                isAdminLogined: true,
                token: action.payload.token.accessToken,
                isAuthenticated: true,
            };

        case ADMIN_PASSWORD_RESETING:
            return {
                ...state,
                isAdminPasswordReseting: true,
                isAdminPasswordReseted: false,
                err: null,
            };

        case ADMIN_PASSWORD_RESETED:
            return {
                ...state,
                isAdminPasswordReseting: false,
                isAdminPasswordReseted: true,
            };


        default:
            return state;
    }
}