import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Icon,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";

import { GrAdd } from 'react-icons/gr';
import { FaEye } from "react-icons/fa";

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
  addProduct,
  editProduct,
  deleteProduct,
} from '../../actions/admin.js'

const ModalProduct = ({ product, title, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDisabled, setIsDisabled] = useState(type === "view" ? true : false);
  const [modalType, setModalType] = useState(type === "view" ? "view" : "add");

  // All field values in the form
  const [id] = useState(product ? product._id : null);
  const [name, setName] = useState(product ? product.name : '');
  const [description, setDescription] = useState(product ? product.description : '');
  const [price, setPrice] = useState(product ? product.price : '');
  const [hsn, setHsn] = useState(product ? product.hsn : '');
  const [unit, setUnit] = useState(product ? product.unit : '');
  const [gst_percentage, setGst_percentage] = useState(product ? product.gst_percentage : '');
  const [cgst_percentage, setCgst_percentage] = useState(product ? product.cgst_percentage : '');
  const [sgst_percentage, setSgst_percentage] = useState(product ? product.sgst_percentage : '');
  const [igst_percentage, setIgst_percentage] = useState(product ? product.igst_percentage : '');
  const [stock_left, setStock_left] = useState(product ? product.stock_left : '');
  const [is_available, setIs_available] = useState(product ? product.is_available : false);


  const dispatch = useDispatch();

  const isProductAdded = useSelector(state => state.admin.isProductAdded);
  const isProductEdited = useSelector(state => state.admin.isProductEdited);
  const isProductDeleted = useSelector(state => state.admin.isProductDeleted);

  const handleChange = (e) => {
    e.preventDefault();
    setIsDisabled(!isDisabled);
    if (isDisabled === false) {
      dispatch(editProduct(id, name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available));
      setIsDisabled(true);
    }
  }

  // Create a new product
  const handleCreate = (e) => {
    e.preventDefault();
    dispatch(addProduct(name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available));
  }

  // Delete product
  const handleDelete = (e) => {
    e.preventDefault();
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (confirmDelete) {
      dispatch(deleteProduct(id));
    }
  }

  useEffect(() => {
    if (isProductAdded || isProductEdited || isProductDeleted) {
      onClose();
    }
  }, [isProductAdded, isProductEdited, isProductDeleted]);

  return (
    <>
      {modalType === "view" ? <Button p="0px" bg="transparent" onClick={onOpen}>
        <Icon as={FaEye} color="gray.400" cursor="pointer" />
      </Button> :
        <Button onClick={onOpen} style={{ marginTop: "2px" }}>
          <GrAdd />
        </Button>
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {modalType === "view" ? <ModalHeader>{isDisabled ? "View" : "Edit"} {title}</ModalHeader> : <ModalHeader>{title}</ModalHeader>}
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor='name'>Name</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>Description</FormLabel>
              <Textarea id='name' type='name' isDisabled={isDisabled} defaultValue={description} onChange={(e) => setDescription(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>Price</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={price} onChange={(e) => setPrice(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>HSN</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={hsn} onChange={(e) => setHsn(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>Unit</FormLabel>
              <Select id='name' isDisabled={isDisabled} defaultValue={unit} onChange={(e) => setUnit(e.target.value)}>
                <option value="">Select Unit</option>
                <option value="kilogram">Kilogram</option>
                <option value="liter">Liter</option>
                <option value="number">Number</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>GST Percentage</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={gst_percentage} onChange={(e) => setGst_percentage(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>CGST Percentage</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={cgst_percentage} onChange={(e) => setCgst_percentage(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>SGST Percentage</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={sgst_percentage} onChange={(e) => setSgst_percentage(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>IGST Percentage</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={igst_percentage} onChange={(e) => setIgst_percentage(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>Stock Left</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={stock_left} onChange={(e) => setStock_left(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name' mt={2}>Is Available</FormLabel>
              <Select id='name' isDisabled={isDisabled} defaultValue={is_available} onChange={(e) => setIs_available(e.target.value)}>
                <option value="">Select Availability</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            {type === "view" ?
              <Button colorScheme={isDisabled ? 'orange' : 'green'} mr={3} onClick={(e) => { handleChange(e) }}>
                {isDisabled ? "Edit" : "Save"}
              </Button>
              :
              <Button colorScheme='green' onClick={(e) => { handleCreate(e) }}>Add</Button>}
            {
              type === "view" ?
                <Button colorScheme='red' onClick={(e) => { handleDelete(e) }}>Delete</Button>
                : ""
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalProduct