import {
    CUSTOMER_ADDING,
    CUSTOMER_ADDED,
    CUSTOMER_ADD_ERROR,
    CUSTOMER_LOADING,
    CUSTOMER_LOADED,
    CUSTOMER_LOAD_ERROR,
    CUSTOMER_EDITING,
    CUSTOMER_EDITED,
    CUSTOMER_EDIT_ERROR,
    CUSTOMER_DELETING,
    CUSTOMER_DELETED,
    CUSTOMER_DELETE_ERROR,
    CUSTOMERS_LOADING,
    CUSTOMERS_LOADED,
    CUSTOMERS_LOADING_ERROR,
    CUSTOMERS_SEARCHING,
    CUSTOMERS_SEARCHED,
    CUSTOMERS_SEARCH_ERROR,
    PRODUCTS_LOADING,
    PRODUCTS_LOADED,
    PRODUCTS_LOAD_ERROR,
    PRODUCT_ADDING,
    PRODUCT_ADDED,
    PRODUCT_ADD_ERROR,
    PRODUCT_LOADING,
    PRODUCT_LOADED,
    PRODUCT_LOAD_ERROR,
    PRODUCT_EDITING,
    PRODUCT_EDITED,
    PRODUCT_EDIT_ERROR,
    PRODUCT_DELETING,
    PRODUCT_DELETED,
    PRODUCT_DELETE_ERROR,
    PRODUCT_SEARCHING,
    PRODUCT_SEARCHED,
    PRODUCT_SEARCH_ERROR,
    QUOTATION_LOADING,
    QUOTATIONS_LOADING,
    QUOTATIONS_LOADED,
    QUOTATIONS_LOAD_ERROR,
    QUOTATION_LOADED,
    QUOTATION_LOAD_ERROR,
    QUOTATION_ADDING,
    QUOTATION_ADDED,
    QUOTATION_ADD_ERROR,
    QUOTATION_EDITING,
    QUOTATION_EDITED,
    QUOTATION_EDIT_ERROR,
    QUOTATION_DELETING,
    QUOTATION_DELETED,
    QUOTATION_DELETE_ERROR,
    QUOTATION_PRINTING,
    QUOTATION_PRINTED,
    QUOTATION_PRINT_ERROR,
    QUOTATION_SEARCHING,
    QUOTATION_SEARCHED,
    QUOTATION_SEARCH_ERROR,
    STATES_LOADING,
    STATES_LOADED,
    STATES_LOAD_ERROR,
    ITEMS_CALCULATING,
    ITEMS_CALCULATED,
    ITEMS_CALCULATE_ERROR,
    QUOTATION_DOWNLOADING,
    QUOTATION_DOWNLOADED,
    QUOTATION_DOWNLOAD_ERROR,
    SALES_LOADING,
    SALES_LOADED,
    SALES_LOAD_ERROR,
    SALE_LOADING,
    SALE_LOADED,
    SALE_LOAD_ERROR,
    SALE_ADDING,
    SALE_ADDED,
    SALE_ADD_ERROR,
    SALE_EDITING,
    SALE_EDITED,
    SALE_EDIT_ERROR,
    SALE_DELETING,
    SALE_DELETED,
    SALE_DELETE_ERROR,
    SALE_DOWNLOADING,
    SALE_DOWNLOADED,
    SALE_DOWNLOAD_ERROR,
    SALE_PRINTING,
    SALE_PRINTED,
    SALE_PRINT_ERROR,
    SALE_SEARCHING,
    SALE_SEARCHED,
    SALE_SEARCH_ERROR,
} from '../actions/types';

const initialState = {
    isCustomersAdding: false,
    isCustomersAdded: false,
    isCustomerLoading: false,
    isCustomerLoaded: false,
    isCustomerEditing: false,
    isCustomerEdited: false,
    isCustomerDeleting: false,
    isCustomerDeleted: false,
    isCustomersLoading: false,
    isCustomersLoaded: false,
    isCustomersSearching: false,
    isCustomersSearched: false,
    isProductsLoading: false,
    isProductsLoaded: false,
    isProductLoading: false,
    isProductLoaded: false,
    isProductAdding: false,
    isProductAdded: false,
    isProductEditing: false,
    isProductEdited: false,
    isProductDeleting: false,
    isProductDeleted: false,
    isProductSearching: false,
    isProductSearched: false,
    isQuotationsLoading: false,
    isQuotationsLoaded: false,
    isQuotationLoading: false,
    isQuotationLoaded: false,
    isQuotationAdding: false,
    isQuotationAdded: false,
    isQuotationEditing: false,
    isQuotationEdited: false,
    isQuotationDeleting: false,
    isQuotationDeleted: false,
    isQuotationPrinting: false,
    isQuotationPrinted: false,
    isQuotationSearching: false,
    isQuotationSearched: false,
    isStatesLoading: false,
    isStatesLoaded: false,
    isItemsCalculating: false,
    isItemsCalculated: false,
    isQuotationDownloading: false,
    isQuotationDownloaded: false,
    isSalesLoading: false,
    isSalesLoaded: false,
    isSaleLoading: false,
    isSaleLoaded: false,
    isSaleAdding: false,
    isSaleAdded: false,
    isSaleEditing: false,
    isSaleEdited: false,
    isSaleDeleting: false,
    isSaleDeleted: false,
    isSalePrinting: false,
    isSalePrinted: false,
    isSaleSearching: false,
    isSaleSearched: false,
    isSaleDownloading: false,
    isSaleDownloaded: false,
    quotation: [],
    sale: [],
    err: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_ADD_ERROR:
        case CUSTOMER_LOAD_ERROR:
        case CUSTOMER_EDIT_ERROR:
        case CUSTOMER_DELETE_ERROR:
        case CUSTOMERS_LOADING_ERROR:
        case CUSTOMERS_SEARCH_ERROR:
        case PRODUCTS_LOAD_ERROR:
        case PRODUCT_ADD_ERROR:
        case PRODUCT_LOAD_ERROR:
        case PRODUCT_EDIT_ERROR:
        case PRODUCT_DELETE_ERROR:
        case PRODUCT_SEARCH_ERROR:
        case QUOTATIONS_LOAD_ERROR:
        case QUOTATION_LOAD_ERROR:
        case QUOTATION_ADD_ERROR:
        case QUOTATION_EDIT_ERROR:
        case QUOTATION_DELETE_ERROR:
        case QUOTATION_PRINT_ERROR:
        case QUOTATION_SEARCH_ERROR:
        case STATES_LOAD_ERROR:
        case ITEMS_CALCULATE_ERROR:
        case QUOTATION_DOWNLOAD_ERROR:
        case SALES_LOAD_ERROR:
        case SALE_LOAD_ERROR:
        case SALE_ADD_ERROR:
        case SALE_EDIT_ERROR:
        case SALE_DELETE_ERROR:
        case SALE_DOWNLOAD_ERROR:
        case SALE_SEARCH_ERROR:
            return {
                ...state,
                err: action.payload,
            };

        case CUSTOMER_ADDING:
            return {
                ...state,
                isCustomersAdding: true,
                isCustomersAdded: false,
                err: '',
            };

        case CUSTOMER_ADDED:
            return {
                ...state,
                isCustomersAdding: false,
                isCustomersAdded: true,
                ...action.payload,
            };

        case CUSTOMER_LOADING:
            return {
                ...state,
                isCustomerLoading: true,
                isCustomerLoaded: false,
                message: ''
            };

        case CUSTOMER_LOADED:
            return {
                ...state,
                isCustomerLoading: false,
                isCustomerLoaded: true,
                ...action.payload,
            };

        case CUSTOMER_EDITING:
            return {
                ...state,
                isCustomerEditing: true,
                isCustomerEdited: false,
                err: '',
            };

        case CUSTOMER_EDITED:
            return {
                ...state,
                isCustomerEditing: false,
                isCustomerEdited: true,
                ...action.payload,
            };

        case CUSTOMER_DELETING:
            return {
                ...state,
                isCustomerDeleting: true,
                isCustomerDeleted: false,
                err: '',
            };

        case CUSTOMER_DELETED:
            return {
                ...state,
                isCustomerDeleting: false,
                isCustomerDeleted: true,
                ...action.payload,
            };

        case CUSTOMERS_LOADING:
            return {
                ...state,
                isCustomersLoading: true,
                isCustomersLoaded: false,
                err: '',
            };

        case CUSTOMERS_LOADED:
            return {
                ...state,
                isCustomersLoading: false,
                isCustomersLoaded: true,
                ...action.payload,
            };

        case CUSTOMERS_SEARCHING:
            return {
                ...state,
                isCustomersSearching: true,
                isCustomersSearched: false,
                err: '',
            };

        case CUSTOMERS_SEARCHED:
            return {
                ...state,
                isCustomersSearching: false,
                isCustomersSearched: true,
                customers: action.payload.customers,
            };

        case PRODUCTS_LOADING:
            return {
                ...state,
                isProductsLoading: true,
                isProductsLoaded: false,
                err: '',
            };

        case PRODUCTS_LOADED:
            return {
                ...state,
                isProductsLoading: false,
                isProductsLoaded: true,
                ...action.payload,
            };

        case PRODUCT_LOADING:
            return {
                ...state,
                isProductLoading: true,
                isProductLoaded: false,
                err: '',
            };

        case PRODUCT_LOADED:
            return {
                ...state,
                isProductLoading: false,
                isProductLoaded: true,
                ...action.payload,
            };

        case PRODUCT_ADDING:
            return {
                ...state,
                isProductAdding: true,
                isProductAdded: false,
                err: '',
            };

        case PRODUCT_ADDED:
            return {
                ...state,
                isProductAdding: false,
                isProductAdded: true,
                ...action.payload,
            };

        case PRODUCT_EDITING:
            return {
                ...state,
                isProductEditing: true,
                isProductEdited: false,
                err: '',
            };

        case PRODUCT_EDITED:
            return {
                ...state,
                isProductEditing: false,
                isProductEdited: true,
                ...action.payload,
            };

        case PRODUCT_DELETING:
            return {
                ...state,
                isProductDeleting: true,
                isProductDeleted: false,
                err: '',
            };

        case PRODUCT_DELETED:
            return {
                ...state,
                isProductDeleting: false,
                isProductDeleted: true,
                ...action.payload,
            };

        case PRODUCT_SEARCHING:
            return {
                ...state,
                isProductSearching: true,
                isProductSearched: false,
                err: '',
            };

        case PRODUCT_SEARCHED:
            return {
                ...state,
                isProductSearching: false,
                isProductSearched: true,
                products: action.payload.products,
            };

        case QUOTATION_LOADING:
            return {
                ...state,
                isQuotationLoading: true,
                isQuotationLoaded: false,
                err: '',
            };

        case QUOTATION_LOADED:
            return {
                ...state,
                isQuotationLoading: false,
                isQuotationLoaded: true,
                ...action.payload,
            };

        case QUOTATION_ADDING:
            return {
                ...state,
                isQuotationAdding: true,
                isQuotationAdded: false,
                err: '',
            };

        case QUOTATION_ADDED:
            return {
                ...state,
                isQuotationAdding: false,
                isQuotationAdded: true,
                ...action.payload,
            };

        case QUOTATION_EDITING:
            return {
                ...state,
                isQuotationEditing: true,
                isQuotationEdited: false,
                err: '',
            };

        case QUOTATION_EDITED:
            return {
                ...state,
                isQuotationEditing: false,
                isQuotationEdited: true,
                ...action.payload,
            };

        case QUOTATION_DELETING:
            return {
                ...state,
                isQuotationDeleting: true,
                isQuotationDeleted: false,
                err: '',
            };

        case QUOTATION_DELETED:
            return {
                ...state,
                isQuotationDeleting: false,
                isQuotationDeleted: true,
                ...action.payload,
            };

        case QUOTATIONS_LOADING:
            return {
                ...state,
                isQuotationsLoading: true,
                isQuotationsLoaded: false,
                customers: [],
                err: '',
            };

        case QUOTATIONS_LOADED:
            return {
                ...state,
                isQuotationsLoading: false,
                isQuotationsLoaded: true,
                ...action.payload,
            };

        case QUOTATION_SEARCHING:
            return {
                ...state,
                isQuotationsSearching: true,
                isQuotationsSearched: false,
                err: '',
            };

        case QUOTATION_SEARCHED:
            return {
                ...state,
                isQuotationsSearching: false,
                isQuotationsSearched: true,
                quotations: action.payload.quotations,
            };

        case SALE_LOADING:
            return {
                ...state,
                isSaleLoading: true,
                isSaleLoaded: false,
                err: '',
            };

        case SALE_LOADED:
            return {
                ...state,
                isSaleLoading: false,
                isSaleLoaded: true,
                ...action.payload,
            };

        case SALE_ADDING:
            return {
                ...state,
                isSaleAdding: true,
                isSaleAdded: false,
                err: '',
            };

        case SALE_ADDED:
            return {
                ...state,
                isSaleAdding: false,
                isSaleAdded: true,
                ...action.payload,
            };

        case SALE_EDITING:
            return {
                ...state,
                isSaleEditing: true,
                isSaleEdited: false,
                err: '',
            };

        case SALE_EDITED:
            return {
                ...state,
                isSaleEditing: false,
                isSaleEdited: true,
                ...action.payload,
            };

        case SALE_DELETING:
            return {
                ...state,
                isSaleDeleting: true,
                isSaleDeleted: false,
                err: '',
            };

        case SALE_DELETED:
            return {
                ...state,
                isSaleDeleting: false,
                isSaleDeleted: true,
                ...action.payload,
            };

        case SALES_LOADING:
            return {
                ...state,
                isSalesLoading: true,
                isSalesLoaded: false,
                customers: [],
                err: '',
            };

        case SALES_LOADED:
            return {
                ...state,
                isSalesLoading: false,
                isSalesLoaded: true,
                ...action.payload,
            };

        case SALE_SEARCHING:
            return {
                ...state,
                isSalesSearching: true,
                isSalesSearched: false,
                err: '',
            };

        case SALE_SEARCHED:
            return {
                ...state,
                isSalesSearching: false,
                isSalesSearched: true,
                sales: action.payload.quotations,
            };

        case STATES_LOADING:
            return {
                ...state,
                isStatesLoading: true,
                isStatesLoaded: false,
                err: '',
            };

        case STATES_LOADED:
            return {
                ...state,
                isStatesLoading: false,
                isStatesLoaded: true,
                ...action.payload,
            };
        case ITEMS_CALCULATING:
            return {
                ...state,
                isItemsCalculating: true,
                isItemsCalculated: false,
                err: '',
            };
        case ITEMS_CALCULATED:
            return {
                ...state,
                isItemsCalculating: false,
                isItemsCalculated: true,
                ...action.payload,
            };
        case QUOTATION_DOWNLOADING:
            return {
                ...state,
                isQuotationDownloading: true,
                isQuotationDownloaded: false,
            };
        case QUOTATION_DOWNLOADED:
            return {
                ...state,
                isQuotationDownloading: false,
                isQuotationDownloaded: true,
                ...action.payload
            }

        case SALE_DOWNLOADING:
            return {
                ...state,
                isSaleDownloading: true,
                isSaleDownloaded: false,
            };
        case SALE_DOWNLOADED:
            return {
                ...state,
                isSaleDownloading: false,
                isSaleDownloaded: true,
                ...action.payload
            }

        default:
            return state;
    }
}