import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge,
  Link
} from "@chakra-ui/react";

import { Link as ReachLink } from "react-router-dom";

import { FaDownload, FaEye } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

import ModalQuotation from '../Modal/ModalQuotation.js';
import { getColor } from "@chakra-ui/theme-tools";
import Quotations from "views/Dashboard/Quotations.js";
import { useDispatch } from "react-redux";
import { deleteQuotation, getQuotationPdf } from "actions/admin.js";

function TableQuotation(props) {

  const dispatch = useDispatch();

  const textColor = useColorModeValue("gray.700", "white");
  const colors = [
    "red",
    "orange",
    "green",
    "teal",
  ]
  const getColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // get dd/mm/yyyy from timestamp
  const getDate = (timestamp) => {
    var date = new Date(timestamp);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return day + '/' + month + '/' + year;
  }

  const linkTo = {
    pathname: "/admin/quotations/" + props.quotation._id,
    state: {
      quotation: props.quotation
    }
  }

  // Hanldes delete
  const handleDelete = (id) => {
    const confirm = window.confirm('Are you sure you want to delete the quotaion?');
    if (confirm) {
      dispatch(deleteQuotation(id));
    }
  }

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"24px"} w={"24px"} me="18px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            <Badge fontSize='1em' colorScheme={getColor()} variant='solid'>{props.quotation.bill_to.name}</Badge>
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.quotation.bill_no}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {getDate(props.quotation.date)}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.quotation.items.length}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.quotation.total_amount}
        </Text>
      </Td>
      <Td>
        {/* <ModalQuotation title="Customer" quotation={props.quotation} type="view" /> */}
        <Link
          as={ReachLink}
          to={linkTo}
        >
          <Button p="0px" bg="transparent">
            <Icon as={FaEye} color="gray.400" cursor="pointer" />
          </Button>
        </Link>
        <Button p="0px" bg="transparent" onClick={() => handleDelete(props.quotation._id)}>
          <Icon as={BsTrash} color="gray.400" cursor="pointer" />
        </Button>
        <Button p="0px" bg="transparent" onClick={() => { dispatch(getQuotationPdf(props.quotation._id)) }}>
          <Icon as={FaDownload} color="gray.400" cursor="pointer" />
        </Button>
      </Td>
    </Tr>
  );
}

export default TableQuotation;
