export const ADMIN_LOADING = 'ADMIN_LOADING';
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const ADMIN_LOAD_ERROR = 'ADMIN_LOAD_ERROR';

export const ADMIN_REGISTERING = 'ADMIN_REGISTERING';
export const ADMIN_REGISTERED = 'ADMIN_REGISTERED';
export const ADMIN_REGISTER_ERROR = 'ADMIN_REGISTER_ERROR';

export const ADMIN_LOGINING = 'ADMIN_LOGINING';
export const ADMIN_LOGINED = 'ADMIN_LOGINED';
export const ADMIN_LOGIN_ERROR = 'ADMIN_LOGIN_ERROR';

export const ADMIN_PASSWORD_RESETING = 'ADMIN_PASSWORD_RESETING';
export const ADMIN_PASSWORD_RESETED = 'ADMIN_PASSWORD_RESETED';
export const ADMIN_PASSWORD_RESET_ERROR = 'ADMIN_PASSWORD_RESET_ERROR';

export const ADMIN_TOKEN_REFRESHING = 'ADMIN_TOKEN_REFRESHING';
export const ADMIN_TOKEN_REFRESHED = 'ADMIN_TOKEN_REFRESHED';
export const ADMIN_TOKEN_REFRESH_ERROR = 'ADMIN_TOKEN_REFRESH_ERROR';

export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const CUSTOMER_ADDING = 'CUSTOMER_ADDING';
export const CUSTOMER_ADDED = 'CUSTOMER_ADDED';
export const CUSTOMER_ADD_ERROR = 'CUSTOMER_ADD_ERROR';

export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';
export const CUSTOMER_LOAD_ERROR = 'CUSTOMER_LOAD_ERROR';

export const CUSTOMER_EDITING = 'CUSTOMER_EDITING';
export const CUSTOMER_EDITED = 'CUSTOMER_EDITED';
export const CUSTOMER_EDIT_ERROR = 'CUSTOMER_EDIT_ERROR';

export const CUSTOMER_DELETING = 'CUSTOMER_DELETING';
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED';
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR';

export const CUSTOMERS_LOADING = 'CUSTOMERS_LOADING';
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
export const CUSTOMERS_LOADING_ERROR = 'CUSTOMERS_LOADING_ERROR';

export const CUSTOMERS_SEARCHING = 'CUSTOMERS_SEARCHING';
export const CUSTOMERS_SEARCHED = 'CUSTOMERS_SEARCHED';
export const CUSTOMERS_SEARCH_ERROR = 'CUSTOMERS_SEARCH_ERROR';

export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCTS_LOAD_ERROR = 'PRODUCTS_LOAD_ERROR';

export const PRODUCT_ADDING = 'PRODUCT_ADDING';
export const PRODUCT_ADDED = 'PRODUCT_ADDED';
export const PRODUCT_ADD_ERROR = 'PRODUCT_ADD_ERROR';

export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const PRODUCT_LOAD_ERROR = 'PRODUCT_LOAD_ERROR';

export const PRODUCT_EDITING = 'PRODUCT_EDITING';
export const PRODUCT_EDITED = 'PRODUCT_EDITED';
export const PRODUCT_EDIT_ERROR = 'PRODUCT_EDIT_ERROR';

export const PRODUCT_DELETING = 'PRODUCT_DELETING';
export const PRODUCT_DELETED = 'PRODUCT_DELETED';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';

export const PRODUCT_SEARCHING = 'PRODUCT_SEARCHING';
export const PRODUCT_SEARCHED = 'PRODUCT_SEARCHED';
export const PRODUCT_SEARCH_ERROR = 'PRODUCT_SEARCH_ERROR';

export const QUOTATIONS_LOADING = 'QUOTATIONS_LOADING';
export const QUOTATIONS_LOADED = 'QUOTATIONS_LOADED';
export const QUOTATIONS_LOAD_ERROR = 'QUOTATIONS_LOAD_ERROR';

export const QUOTATION_LOADING = 'QUOTATION_LOADING';
export const QUOTATION_LOADED = 'QUOTATION_LOADED';
export const QUOTATION_LOAD_ERROR = 'QUOTATION_LOAD_ERROR';

export const QUOTATION_ADDING = 'QUOTATION_ADDING';
export const QUOTATION_ADDED = 'QUOTATION_ADDED';
export const QUOTATION_ADD_ERROR = 'QUOTATION_ADD_ERROR';

export const QUOTATION_EDITING = 'QUOTATION_EDITING';
export const QUOTATION_EDITED = 'QUOTATION_EDITED';
export const QUOTATION_EDIT_ERROR = 'QUOTATION_EDIT_ERROR';

export const QUOTATION_DELETING = 'QUOTATION_DELETING';
export const QUOTATION_DELETED = 'QUOTATION_DELETED';
export const QUOTATION_DELETE_ERROR = 'QUOTATION_DELETE_ERROR';

export const QUOTATION_DOWNLOADING = 'QUOTATION_DOWNLOADING';
export const QUOTATION_DOWNLOADED = 'QUOTATION_DOWNLOADED';
export const QUOTATION_DOWNLOAD_ERROR = 'QUOTATION_DOWNLOAD_ERROR';

export const QUOTATION_PRINTING = 'QUOTATION_PRINTING';
export const QUOTATION_PRINTED = 'QUOTATION_PRINTED';
export const QUOTATION_PRINT_ERROR = 'QUOTATION_PRINT_ERROR';

export const QUOTATION_SEARCHING = 'QUOTATION_SEARCHING';
export const QUOTATION_SEARCHED = 'QUOTATION_SEARCHED';
export const QUOTATION_SEARCH_ERROR = 'QUOTATION_SEARCH_ERROR';

export const SALES_LOADING = 'SALES_LOADING';
export const SALES_LOADED = 'SALES_LOADED';
export const SALES_LOAD_ERROR = 'SALES_LOAD_ERROR';

export const SALE_LOADING = 'SALE_LOADING';
export const SALE_LOADED = 'SALE_LOADED';
export const SALE_LOAD_ERROR = 'SALE_LOAD_ERROR';

export const SALE_ADDING = 'SALE_ADDING';
export const SALE_ADDED = 'SALE_ADDED';
export const SALE_ADD_ERROR = 'SALE_ADD_ERROR';

export const SALE_EDITING = 'SALE_EDITING';
export const SALE_EDITED = 'SALE_EDITED';
export const SALE_EDIT_ERROR = 'SALE_EDIT_ERROR';

export const SALE_DELETING = 'SALE_DELETING';
export const SALE_DELETED = 'SALE_DELETED';
export const SALE_DELETE_ERROR = 'SALE_DELETE_ERROR';

export const SALE_DOWNLOADING = 'SALE_DOWNLOADING';
export const SALE_DOWNLOADED = 'SALE_DOWNLOADED';
export const SALE_DOWNLOAD_ERROR = 'SALE_DOWNLOAD_ERROR';

export const SALE_PRINTING = 'SALE_PRINTING';
export const SALE_PRINTED = 'SALE_PRINTED';
export const SALE_PRINT_ERROR = 'SALE_PRINT_ERROR';

export const SALE_SEARCHING = 'SALE_SEARCHING';
export const SALE_SEARCHED = 'SALE_SEARCHED';
export const SALE_SEARCH_ERROR = 'SALE_SEARCH_ERROR';

export const STATES_LOADING = 'STATES_LOADING';
export const STATES_LOADED = 'STATES_LOADED';
export const STATES_LOAD_ERROR = 'STATES_LOAD_ERROR';

export const ITEMS_CALCULATING = 'ITEMS_CALCULATING';
export const ITEMS_CALCULATED = 'ITEMS_CALCULATED';
export const ITEMS_CALCULATE_ERROR = 'ITEMS_CALCULATE_ERROR';