import axios from "axios";
import { tokenConfig } from "./config";
import { customToast } from "../helpers/toast.js";

import { saveAs } from 'file-saver';

import {
    CUSTOMER_ADDING,
    CUSTOMER_ADDED,
    CUSTOMER_ADD_ERROR,
    CUSTOMER_LOADING,
    CUSTOMER_LOADED,
    CUSTOMER_LOAD_ERROR,
    CUSTOMER_EDITING,
    CUSTOMER_EDITED,
    CUSTOMER_EDIT_ERROR,
    CUSTOMER_DELETING,
    CUSTOMER_DELETED,
    CUSTOMER_DELETE_ERROR,
    CUSTOMERS_LOADING,
    CUSTOMERS_LOADED,
    CUSTOMERS_LOADING_ERROR,
    CUSTOMERS_SEARCHING,
    CUSTOMERS_SEARCHED,
    CUSTOMERS_SEARCH_ERROR,
    PRODUCTS_LOADING,
    PRODUCTS_LOADED,
    PRODUCTS_LOAD_ERROR,
    PRODUCT_ADDING,
    PRODUCT_ADDED,
    PRODUCT_ADD_ERROR,
    PRODUCT_LOADING,
    PRODUCT_LOADED,
    PRODUCT_LOAD_ERROR,
    PRODUCT_EDITING,
    PRODUCT_EDITED,
    PRODUCT_EDIT_ERROR,
    PRODUCT_DELETING,
    PRODUCT_DELETED,
    PRODUCT_DELETE_ERROR,
    PRODUCT_SEARCHING,
    PRODUCT_SEARCHED,
    PRODUCT_SEARCH_ERROR,
    QUOTATIONS_LOADING,
    QUOTATIONS_LOADED,
    QUOTATIONS_LOAD_ERROR,
    QUOTATION_LOADING,
    QUOTATION_LOADED,
    QUOTATION_LOAD_ERROR,
    QUOTATION_ADDING,
    QUOTATION_ADDED,
    QUOTATION_ADD_ERROR,
    QUOTATION_EDITING,
    QUOTATION_EDITED,
    QUOTATION_EDIT_ERROR,
    QUOTATION_DELETING,
    QUOTATION_DELETED,
    QUOTATION_DELETE_ERROR,
    QUOTATION_PRINTING,
    QUOTATION_PRINTED,
    QUOTATION_PRINT_ERROR,
    QUOTATION_SEARCHING,
    QUOTATION_SEARCHED,
    QUOTATION_SEARCH_ERROR,
    STATES_LOADING,
    STATES_LOADED,
    STATES_LOAD_ERROR,
    ITEMS_CALCULATING,
    ITEMS_CALCULATED,
    ITEMS_CALCULATE_ERROR,
    QUOTATION_DOWNLOADING,
    QUOTATION_DOWNLOADED,
    QUOTATION_DOWNLOAD_ERROR,
    SALES_LOADING,
    SALES_LOADED,
    SALES_LOAD_ERROR,
    SALE_LOADING,
    SALE_LOADED,
    SALE_LOAD_ERROR,
    SALE_ADDING,
    SALE_ADDED,
    SALE_ADD_ERROR,
    SALE_EDITING,
    SALE_EDITED,
    SALE_EDIT_ERROR,
    SALE_DELETING,
    SALE_DELETED,
    SALE_DELETE_ERROR,
    SALE_DOWNLOADING,
    SALE_DOWNLOADED,
    SALE_DOWNLOAD_ERROR,
    SALE_PRINTING,
    SALE_PRINTED,
    SALE_PRINT_ERROR,
    SALE_SEARCHING,
    SALE_SEARCHED,
    SALE_SEARCH_ERROR,
} from './types';

// const base_url = "http://localhost:4000/api/admin";
const base_url = "http://156.67.218.102/v1/api/admin";

// Load all customers
export const loadCustomers = () => (dispatch, getState) => {
    dispatch({
        type: CUSTOMERS_LOADING
    });
    axios
        .get(base_url + "/customers", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: CUSTOMERS_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading customers", "error");
            dispatch({
                type: CUSTOMERS_LOADING_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Search customers
export const searchCustomers = (query) => (dispatch, getState) => {
    dispatch({
        type: CUSTOMERS_SEARCHING
    });
    axios
        .get(base_url + "/customers/search/" + query, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: CUSTOMERS_SEARCHED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error searching customers", "error");
            dispatch({
                type: CUSTOMERS_SEARCH_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Add customer
export const addCustomer = (name, email, phone, gst_no, company_name, address, city, district, state, pincode) => (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_ADDING
    });
    const body = JSON.stringify({ name, email, phone, gst_no, company_name, address, city, district, state, pincode });
    axios
        .post(base_url + "/customers", body, tokenConfig(getState))
        .then((res) => {
            customToast("Customer added successfully", "success");
            dispatch({
                type: CUSTOMER_ADDED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error adding customer", "error");
            dispatch({
                type: CUSTOMER_ADD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Edit customer
export const editCustomer = (id, name, email, phone, gst_no, company_name, address, city, district, state, pincode) => (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_EDITING
    });
    const body = JSON.stringify({ name, email, phone, gst_no, company_name, address, city, district, state, pincode });
    axios
        .put(base_url + "/customers/" + id, body, tokenConfig(getState))
        .then((res) => {
            customToast("Customer updated successfully", "success");
            dispatch({
                type: CUSTOMER_EDITED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error updating customer", "error");
            dispatch({
                type: CUSTOMER_EDIT_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Delete customer
export const deleteCustomer = (id) => (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_DELETING
    });
    axios
        .delete(base_url + "/customers/" + id, tokenConfig(getState))
        .then((res) => {
            customToast("Customer deleted successfully", "success");
            dispatch({
                type: CUSTOMER_DELETED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error deleting customer", "error");
            dispatch({
                type: CUSTOMER_DELETE_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Load customer
export const loadCustomer = (id) => (dispatch, getState) => {
    dispatch({
        type: CUSTOMER_LOADING
    });
    axios
        .get(base_url + "/customers/" + id, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: CUSTOMER_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading customer", "error");
            dispatch({
                type: CUSTOMER_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Load all products
export const loadProducts = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCTS_LOADING
    });
    axios
        .get(base_url + "/products", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: PRODUCTS_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading products", "error");
            dispatch({
                type: PRODUCTS_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Add product
export const addProduct = (name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_ADDING
    });
    const body = JSON.stringify({ name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available });
    axios
        .post(base_url + "/products", body, tokenConfig(getState))
        .then((res) => {
            customToast("Product added successfully", "success");
            dispatch({
                type: PRODUCT_ADDED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error adding product", "error");
            dispatch({
                type: PRODUCT_ADD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Edit product
export const editProduct = (id, name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_EDITING
    });
    const body = JSON.stringify({ name, description, price, hsn, unit, gst_percentage, cgst_percentage, sgst_percentage, igst_percentage, stock_left, is_available });
    axios
        .put(base_url + "/products/" + id, body, tokenConfig(getState))
        .then((res) => {
            customToast("Product updated successfully", "success");
            dispatch({
                type: PRODUCT_EDITED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error updating product", "error");
            dispatch({
                type: PRODUCT_EDIT_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Delete product
export const deleteProduct = (id) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_DELETING
    });
    axios
        .delete(base_url + "/products/" + id, tokenConfig(getState))
        .then((res) => {
            customToast("Product deleted successfully", "success");
            dispatch({
                type: PRODUCT_DELETED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error deleting product", "error");
            dispatch({
                type: PRODUCT_DELETE_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Load product
export const loadProduct = (id) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_LOADING
    });
    axios
        .get(base_url + "/products/" + id, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: PRODUCT_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading product", "error");
            dispatch({
                type: PRODUCT_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Search products
export const searchProducts = (search) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_SEARCHING
    });
    axios
        .get(base_url + "/products/search/" + search, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: PRODUCT_SEARCHED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error searching products", "error");
            dispatch({
                type: PRODUCT_SEARCH_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Calculate items 
export const calculateItems = (items, from, to, showToast = true) => (dispatch, getState) => {
    dispatch({
        type: ITEMS_CALCULATING
    });

    // Custom toast if states is not selected 
    if (from === '' || to === '') {
        if (showToast === true) {
            customToast("Please select states for calculating", "error");
        }
        return 0;
    }

    const body = JSON.stringify({ items, from, to });
    axios
        .post(base_url + "/calculateitems", body, tokenConfig(getState))
        .then((res) => {
            if (showToast === true) {
                customToast("Items Calculated", "success");
            }
            dispatch({
                type: ITEMS_CALCULATED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error calculating items", "error");
            dispatch({
                type: ITEMS_CALCULATE_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Load all quotations
export const loadQuotations = () => (dispatch, getState) => {
    dispatch({
        type: QUOTATIONS_LOADING
    });
    axios
        .get(base_url + "/quotations", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: QUOTATIONS_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading quotations", "error");
            dispatch({
                type: QUOTATIONS_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Create Quotation
export const createQuotation = (customerId, billingFrom, billingTo, date, vehicleNo, items) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_ADDING
    });
    const body = JSON.stringify({ customerId, billingFrom, billingTo, date, vehicleNo, items });
    axios
        .post(base_url + "/quotations", body, tokenConfig(getState))
        .then((res) => {
            customToast("Quotation created successfully", "success");
            dispatch({
                type: QUOTATION_ADDED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error creating quotation : " + err.response.data.message, "error");
            dispatch({
                type: QUOTATION_ADD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Edit quotation
export const editQuotation = (id, customerId, billingFrom, billingTo, date, vehicleNo, items, billNo) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_EDITING
    });
    const body = JSON.stringify({ customerId, billingFrom, billingTo, date, vehicleNo, items, billNo });
    console.log(body);
    axios
        .put(base_url + "/quotations/" + id, body, tokenConfig(getState))
        .then((res) => {
            customToast("Quotation updated successfully", "success");
            dispatch({
                type: QUOTATION_EDITED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error updating quotation", "error");
            dispatch({
                type: QUOTATION_EDIT_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Delete quotation
export const deleteQuotation = (id) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_DELETING
    });
    axios
        .delete(base_url + "/quotations/" + id, tokenConfig(getState))
        .then((res) => {
            customToast("Quotation deleted successfully", "success");
            dispatch({
                type: QUOTATION_DELETED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error deleting quotation", "error");
            dispatch({
                type: QUOTATION_DELETE_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Quotation PDF 
export const getQuotationPdf = (id) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_DOWNLOADING
    });

    customToast("Downloading Quotation Bill...", "loading");

    axios
        .get(base_url + "/quotations/" + id + "/pdf", tokenConfig(getState))
        .then(() => axios.get(base_url + '/quotations/qpdf', { responseType: 'blob' }))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, id + '_quotation.pdf');
            customToast("", "dismiss");
            customToast("Quotation downloaded successfully", "success");
            dispatch({
                type: QUOTATION_DOWNLOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error deleting quotation", "error");
            dispatch({
                type: QUOTATION_DOWNLOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Search quotations
export const searchQuotations = (search) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_SEARCHING
    });
    axios
        .get(base_url + "/quotations/search/" + search, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: QUOTATION_SEARCHED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error searching quotations", "error");
            dispatch({
                type: QUOTATION_SEARCH_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Get quotation
export const getQuotation = (id) => (dispatch, getState) => {
    dispatch({
        type: QUOTATION_LOADING
    });
    axios
        .get(base_url + "/quotations/" + id, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: QUOTATION_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error getting quotation", "error");
            dispatch({
                type: QUOTATION_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Load all sales
export const loadSales = () => (dispatch, getState) => {
    dispatch({
        type: SALES_LOADING
    });
    axios
        .get(base_url + "/sales", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: SALES_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading sales", "error");
            dispatch({
                type: SALES_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Create Sales
export const createSales = (customerId, billingFrom, billingTo, date, vehicleNo, items) => (dispatch, getState) => {
    dispatch({
        type: SALE_ADDING
    });
    const body = JSON.stringify({ customerId, billingFrom, billingTo, date, vehicleNo, items });
    axios
        .post(base_url + "/sales", body, tokenConfig(getState))
        .then((res) => {
            customToast("Sales created successfully", "success");
            dispatch({
                type: SALE_ADDED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error creating Sales : " + err.response.data.message, "error");
            dispatch({
                type: SALE_ADD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Edit sales
export const editSales = (id, customerId, billingFrom, billingTo, date, vehicleNo, items, billNo) => (dispatch, getState) => {
    dispatch({
        type: SALE_EDITING
    });
    const body = JSON.stringify({ customerId, billingFrom, billingTo, date, vehicleNo, items, billNo });
    console.log(body);
    axios
        .put(base_url + "/sales/" + id, body, tokenConfig(getState))
        .then((res) => {
            customToast("Sales updated successfully", "success");
            dispatch({
                type: SALE_EDITED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error updating sales", "error");
            dispatch({
                type: SALE_EDIT_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Delete sales
export const deleteSales = (id) => (dispatch, getState) => {
    dispatch({
        type: SALE_DELETING
    });
    axios
        .delete(base_url + "/sales/" + id, tokenConfig(getState))
        .then((res) => {
            customToast("Sales deleted successfully", "success");
            dispatch({
                type: SALE_DELETED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error deleting sales", "error");
            dispatch({
                type: SALE_DELETE_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Sales PDF 
export const getSalesPdf = (id) => (dispatch, getState) => {
    dispatch({
        type: SALE_DOWNLOADING
    });
    customToast("Downloading Sales Bill...", "loading");
    axios
        .get(base_url + "/sales/" + id + "/pdf", tokenConfig(getState))
        .then(() => axios.get(base_url + '/sales/qpdf', { responseType: 'blob' }))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, id + '_sales.pdf');
            customToast("", "dismiss");
            customToast("Sales downloaded successfully", "success");
            dispatch({
                type: SALE_DOWNLOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error downloading sales", "error");
            dispatch({
                type: SALE_DOWNLOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Search sales
export const searchSales = (search) => (dispatch, getState) => {
    dispatch({
        type: SALE_SEARCHING
    });
    axios
        .get(base_url + "/sales/search/" + search, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: SALE_SEARCHED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error searching sales", "error");
            dispatch({
                type: SALE_SEARCH_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Get sale
export const getSale = (id) => (dispatch, getState) => {
    dispatch({
        type: SALE_LOADING
    });
    axios
        .get(base_url + "/sales/" + id, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: SALE_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error getting sale", "error");
            dispatch({
                type: SALE_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Bind states
export const bindStates = () => (dispatch, getState) => {
    dispatch({
        type: STATES_LOADING
    });
    axios
        .get(base_url + "/states", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: STATES_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Error loading states", "error");
            dispatch({
                type: STATES_LOAD_ERROR,
                payload: err.response.data.message,
            });
        });
}

