import axios from "axios";
import { tokenConfig } from "./config";
import { customToast } from "../helpers/toast.js";

import {
    ADMIN_LOADING,
    ADMIN_LOADED,
    ADMIN_LOAD_ERROR,
    ADMIN_REGISTERING,
    ADMIN_REGISTERED,
    ADMIN_REGISTER_ERROR,
    ADMIN_LOGINING,
    ADMIN_LOGINED,
    ADMIN_LOGIN_ERROR,
    ADMIN_PASSWORD_RESETING,
    ADMIN_PASSWORD_RESETED,
    ADMIN_PASSWORD_RESET_ERROR,
    ADMIN_TOKEN_REFRESHING,
    ADMIN_TOKEN_REFRESHED,
    ADMIN_TOKEN_REFRESH_ERROR,
    ADMIN_LOGOUT,
} from "./types";

// const base_url = "http://localhost:4000/api/auth";
const base_url = "http://156.67.218.102/v1/api/auth";

// Load admin
export const loadAdmin = () => (dispatch, getState) => {
    dispatch({ type: ADMIN_LOADING });

    axios
        .get(`${base_url}/admin`, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: ADMIN_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Failed to load admin", "error");
            dispatch({
                type: ADMIN_LOAD_ERROR,
                payload: err.response.data,
            });
        });
}

// Register admin
export const registerAdmin = (name, email, password, phone) => (dispatch, getState) => {
    dispatch({ type: ADMIN_REGISTERING });

    const body = JSON.stringify({ name, email, password, phone });

    axios
        .post(`${base_url}/register`, body, tokenConfig(getState))
        .then((res) => {
            customToast("Admin registered successfully", "success");
            dispatch({
                type: ADMIN_REGISTERED,
                payload: res.data,
            });
        })
        .catch((err) => {
            customToast("Failed to register admin", "error");
            console.log(err.response.data);
            dispatch({
                type: ADMIN_REGISTER_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Login admin
export const loginAdmin = (phone, password) => (dispatch, getState) => {
    dispatch({ type: ADMIN_LOGINING });

    const body = JSON.stringify({ phone, password });

    axios
        .post(`${base_url}/login`, body, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: ADMIN_LOGINED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: ADMIN_LOGIN_ERROR,
                payload: err.response.data.message,
            });
        });
}

// Password reset
export const passwordReset = (oldPassword, newPassword) => (dispatch, getState) => {
    dispatch({ type: ADMIN_PASSWORD_RESETING });

    const body = JSON.stringify({ oldPassword, newPassword });

    axios
        .post(`${base_url}/change-password`, body, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: ADMIN_PASSWORD_RESETED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: ADMIN_PASSWORD_RESET_ERROR,
                payload: err.response.data,
            });
        });
}

// Token refresh
export const tokenRefresh = () => (dispatch, getState) => {
    dispatch({ type: ADMIN_TOKEN_REFRESHING });

    axios
        .post(`${base_url}/token`, null, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: ADMIN_TOKEN_REFRESHED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: ADMIN_TOKEN_REFRESH_ERROR,
                payload: err.response.data,
            });
        });
}

// Logout admin
export const logoutAdmin = () => (dispatch, getState) => {
    dispatch({ type: ADMIN_LOGOUT });
}
