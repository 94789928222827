import React from 'react'
import { Toaster } from 'react-hot-toast';


const Toast = () => {
    return (
        <div>
            <Toaster toastOptions={{
                style: {
                    minWidth: '250px',
                    minHeight: '50px',
                },
            }} />
        </div>
    )
}

export default Toast
