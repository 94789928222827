import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Chakra imports
import {
  Button,
  Flex,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesCustomers from "components/Tables/TablesCustomers";
import { tablesProjectData, tablesTableData } from "variables/general";
import { loadCustomers, searchCustomers } from "../../actions/admin";

import ModalCustomer from "../../components/Modal/ModalCustomer.js";

import { FiSearch } from 'react-icons/fi'

function Tables(props) {

  const customers = useSelector(state => state.admin.customers);
  const isCustomerEdited = useSelector(state => state.admin.isCustomerEdited);
  const isCustomerAdded = useSelector(state => state.admin.isCustomersAdded);
  const isCustomerDeleted = useSelector(state => state.admin.isCustomerDeleted);
  const isError = useSelector(state => state.admin.err);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCustomers());
  }, [isCustomerEdited, isCustomerAdded, isCustomerDeleted]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchCustomers(e.target.value));
  }

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        my="22px"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="row" justify="space-around" width={'100%'}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" style={{ marginTop: "10px" }}>
              Customers
            </Text>
            <input type="text" placeholder="Search" className="search-box" onChange={(e) => { handleSearch(e) }} />
            <FiSearch size={24} color={textColor} className="search-icon" />
            <ModalCustomer type="add" title="Add Customer" />
          </Flex>
        </CardHeader>
        <CardBody style={{ overflow: "scroll" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400">
                  Name
                </Th>
                <Th color="gray.400">Email</Th>
                <Th color="gray.400">Phone</Th>
                <Th color="gray.400">GST No.</Th>
                <Th color="gray.400">Company Name</Th>
                <Th color="gray.400">Address</Th>
                <Th color="gray.400">City</Th>
                <Th color="gray.400">District</Th>
                <Th color="gray.400">State</Th>
                <Th color="gray.400">Pincode</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers?.map((row) => {
                return (
                  <TablesCustomers
                    key={row._id}
                    id={row._id}
                    name={row.name}
                    email={row.email}
                    phone={row.phone}
                    gst_no={row.gst_no}
                    company_name={row.company_name}
                    address={row.address}
                    city={row.city}
                    district={row.district}
                    state={row.state}
                    pincode={row.pincode}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Tables;
