import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Chakra imports
import {
  Button,
  Flex,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Select,
  Icon,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import { tablesProjectData, tablesTableData } from "variables/general";
// import { loadQuotations, searchQuotations, bindStates, searchCustomers, searchProducts, calculateItems, createSales } from "../../actions/admin";
import { loadQuotations, searchQuotations, bindStates, searchCustomers, searchProducts, calculateItems, createSales } from "../../actions/admin";

import ModalQuotation from "../../components/Modal/ModalQuotation.js";

import { FiSearch } from 'react-icons/fi'
import TableQuotation from "components/Tables/TableQuotation";
import { FaTimes } from "react-icons/fa";

function SalesAdd(props) {

  const quotations = useSelector(state => state.admin.quotations);
  const isQuotationEdited = useSelector(state => state.admin.isQuotationEdited);
  const isQuotationAdded = useSelector(state => state.admin.isQuotationAdded);
  const isQuotationDeleted = useSelector(state => state.admin.isQuotationDeleted);
  const isItemsCalculated = useSelector(state => state.admin.isItemsCalculated);
  const isError = useSelector(state => state.admin.err);

  // Quotations state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [billingFrom, setBillingFrom] = useState('');
  const [billingTo, setBillingTo] = useState('');
  const [date, setDate] = useState('')
  const [vehicleNo, setVehicleNo] = useState('');

  // Items 
  const [items, setItems] = useState([
    {
      id: Math.floor(1000 + (9999 - 1000) * Math.random()),
      name: '',
      count: 0,
      price: 0.0,
      hsn: '',
      cgst: 0.0,
      sgst: 0.0,
      igst: 0.0,
      total: 0.0
    }
  ])

  const [customersArray, setCustomersArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [customer, setCustomer] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [product, setProduct] = useState('');
  const [productId, setProductId] = useState('');
  const [total, setTotal] = useState({});

  const states = useSelector(state => state.admin.states);
  const customers = useSelector(state => state.admin.customers);
  const products = useSelector(state => state.admin.products);
  const itemsArray = useSelector(state => state.admin.items);

  // Load states on first load 
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bindStates());
  }, []);

  // Add Items to items array with random id for mapping insted of index
  const addItems = () => {
    console.log(items);
    const randomNumber = Math.floor(1000 + (9999 - 1000) * Math.random());
    const oldItems = [...items];
    const newItems = {
      id: randomNumber,
      name: '',
      count: 0,
      price: 0.0,
      hsn: '',
      cgst: 0.0,
      sgst: 0.0,
      igst: 0.0,
      total: 0.0
    }
    oldItems.push(newItems);
    setItems(oldItems);
    // console.log(oldItems);
  }

  // Close the item
  const closeItem = (id) => {
    setItems(items.filter(item => item.id !== id));
  }

  // Search customer and products 
  const handleSearch = (e, searchType) => {
    e.preventDefault();
    let search = e.target.value;
    // if serach is not alphanumeric
    if (!/^[a-zA-Z0-9]+$/.test(search) || search == "") {
      if (searchType === "customer") {
        setCustomersArray([]);
      }
      else {
        setProductsArray([]);
      }
    }
    else {
      if (searchType === "customer") {
        dispatch(searchCustomers(search));
        setCustomersArray(customers);
      }
      else {
        dispatch(searchProducts(search));
        setProductsArray(products);
      }
    }
  }

  // Set the customer 
  const customerRef = useRef();
  const handleCustomer = (customer) => {
    setCustomerId(customer._id);
    setCustomer(customer.name);
    customerRef.current.value = customer.name;
    setCustomersArray([]);
  }

  // Set the product
  const handleProduct = (product, id, i) => {
    console.log(product);
    const productsArray = [...items]
    productsArray[i].id = product._id;
    productsArray[i].name = product.name;
    productsArray[i].count = 1;
    setItems(productsArray);
    setProductsArray([]);
    dispatch(calculateItems(items, billingFrom, billingTo));
  }

  // Handle change in product count 
  const handleProductQuantity = (i, count) => {
    const productsArray = [...items];
    productsArray[i].count = count;
    setItems(productsArray);
    dispatch(calculateItems(items, billingFrom, billingTo));
  }

  // Set items and total once calculating the items array
  useEffect(() => {
    if (isItemsCalculated) {
      setItems(itemsArray.items);
      setTotal(itemsArray.total);
    }
  }, [isItemsCalculated])

  // handle quotation create
  const handleCreate = () => {
    dispatch(createSales(customerId, billingFrom, billingTo, date, vehicleNo, items));
  }

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        my="22px"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="row" justify="space-around" width={'100%'}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" style={{ marginTop: "10px" }}>
              Add Sales
            </Text>
          </Flex>
        </CardHeader>
        <CardBody style={{ overflow: "scroll" }}>
          <div className="card-box">
            <div className="card-field-two">
              <div className="card-field searchrelative">
                <FormLabel htmlFor="name">Name</FormLabel>
                <Input placeholder="Name" onChange={(e) => handleSearch(e, "customer")} ref={customerRef} />
                <div className={customersArray?.length > 0 ? "searchlist shadow" : "d-none"}>
                  <ul className="">
                    {customersArray?.map((customer) => (
                      <li key={customer._id} onClick={() => handleCustomer(customer)}>{customer.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="billing-from">Billing From</FormLabel>
                <Select placeholder="Billing From" onChange={(e) => { setBillingFrom(e.target.value) }}>
                  {states?.map((state) => (<option value={state._id}>{state.name}</option>))}
                </Select>
              </div>
              <div className="card-field">
                <FormLabel htmlFor="billing-to">Billing To</FormLabel>
                <Select placeholder="Billing To" onChange={(e) => { setBillingTo(e.target.value) }} >
                  {states?.map((state) => (<option value={state._id}>{state.name}</option>))}
                </Select>
              </div>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="date">Date</FormLabel>
                <Input placeholder="Date" type="date" onChange={(e) => setDate(e.target.value)} />
              </div>
              <div className="card-field">
                <FormLabel htmlFor="vehicle">Vehicle No.</FormLabel>
                <Input placeholder="Vehicle No." onChange={(e) => setVehicleNo(e.target.value)} />
              </div>
            </div>
            {/* items */}
            <div className="text-center">
              <h3>Items</h3>
            </div>
            <div className="items-container mt-2">
              {items?.map((item, index) => (
                <div className="items" key={item.id}>
                  <div className="item-close">
                    <div className="item-close-icon" onClick={() => { closeItem(item.id) }}>
                      <Icon as={FaTimes} color="red.500" size="1.5em" />
                    </div>
                  </div>
                  <div className="card-field-two">
                    <div className="card-field searchrelative">
                      <FormLabel htmlFor="item-name">Item</FormLabel>
                      <Input placeholder="Item" id={`item${item.id}`} defaultValue={item.name} onChange={(e) => handleSearch(e, "product")} />
                      <div className={productsArray?.length > 0 ? "searchlist shadow" : "d-none"}>
                        <ul className="">
                          {productsArray?.map((product) => (
                            <li key={product._id} onClick={() => handleProduct(product, item.id, index)}>{product.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card-field">
                      <FormLabel htmlFor="item-quantity">Quantity</FormLabel>
                      <Input placeholder="Quantity" defaultValue={item.count} onChange={(e) => { handleProductQuantity(index, e.target.value) }} />
                    </div>
                  </div>
                  <div className="card-field-two">
                    <div className="card-field">
                      <FormLabel htmlFor="item-rate">Price</FormLabel>
                      <Input placeholder="Price" disabled={true} value={item.price?.toFixed(2)} />
                    </div>
                    <div className="card-field">
                      <FormLabel htmlFor="hsn">HSN</FormLabel>
                      <Input placeholder="HSN" disabled={true} value={item.hsn} />
                    </div>
                  </div>
                  <div className="card-field-two">
                    <div className="card-field">
                      <FormLabel htmlFor="cgst">CGST</FormLabel>
                      <Input placeholder="CGST" disabled={true} value={item.cgst?.toFixed(2)} />
                    </div>
                    <div className="card-field">
                      <FormLabel htmlFor="sgst">SGST</FormLabel>
                      <Input placeholder="SGST" disabled={true} value={item.sgst?.toFixed(2)} />
                    </div>
                  </div>
                  <div className="card-field-two">
                    <div className="card-field">
                      <FormLabel htmlFor="igst">IGST</FormLabel>
                      <Input placeholder="IGST" disabled={true} value={item.igst?.toFixed(2)} />
                    </div>
                    <div className="card-field">
                      <FormLabel htmlFor="total">Total</FormLabel>
                      <Input placeholder="Total" disabled={true} value={item.totalPrice?.toFixed(2)} />
                    </div>
                  </div>
                </div>
              ))}
              <button className="add-item-btn orange-btn" onClick={() => addItems()}>Add Item</button>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="total-cgst">Total CGST</FormLabel>
                <Input placeholder="Total CGST" disabled={true} value={total.cgst?.toFixed(2)} />
              </div>
              <div className="card-field">
                <FormLabel htmlFor="total-sgst">Total SGST</FormLabel>
                <Input placeholder="Total SGST" disabled={true} value={total.sgst?.toFixed(2)} />
              </div>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="total-igst">Total IGST</FormLabel>
                <Input placeholder="Total IGST" disabled={true} value={total.igst?.toFixed(2)} />
              </div>
              <div className="card-field">
                <FormLabel htmlFor="total-gst">Total GST</FormLabel>
                <Input placeholder="Total GST" disabled={true} value={total.gst?.toFixed(2)} />
              </div>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="total-amount">Total Amount</FormLabel>
                <Input placeholder="Total Amount" disabled={true} value={total.totalAmount?.toFixed(2)} />
              </div>
            </div>
            <div className="card-field-two">
              <div className="card-field">
                <FormLabel htmlFor="total-amount">Amount in words</FormLabel>
                <Input placeholder="Total Amount" disabled={true} value={total.numberToEnglish} />
              </div>
            </div>
            <button className="add-item-btn green-btn" onClick={handleCreate}>Add Sales</button>
          </div>
        </CardBody>
      </Card>
    </Flex >
  );
}

export default SalesAdd;
