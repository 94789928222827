import toast from 'react-hot-toast';

import infoicon from '../images/info.gif'
import warningicon from '../images/warning.gif'

export const customToast = (message, toastType) => {
    if (toastType === 'loading') {
        document.getElementById('portal').classList.add('modalOverlay')
        var loadingtoast = toast.loading(message)
    }
    else if (toastType === 'dismiss') {
        document.getElementById('portal').classList.remove('modalOverlay')
        toast.remove(loadingtoast)
    }
    else if (toastType === 'error') {
        toast.error(message)
    }
    else if (toastType === 'success') {
        toast.success(message)
    }
    else if (toastType === 'warning') {
        toast(message, {
            icon: <img src={warningicon} alt="warning" style={{ width: '20px', height: '20px' }} />,
        });
    }
    else if (toastType === 'info') {
        toast(message, {
            icon: <img src={infoicon} alt="info" style={{ width: '20px', height: '20px' }} />,
        });
    }
    else if (toastType === 'confirm') {

    }
}

