import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Icon,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Alert,
  AlertIcon
} from "@chakra-ui/react";

import { GrAdd } from 'react-icons/gr';
import { FaEye } from "react-icons/fa";

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
  addCustomer,
  editCustomer,
  deleteCustomer,
} from '../../actions/admin.js'

const ModalCustomer = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDisabled, setIsDisabled] = React.useState(props.type === "view" ? true : false);
  const [modalType, setModalType] = React.useState(props.type === "view" ? "view" : "add");

  // All field values in the form
  const [id] = React.useState(props.customer ? props.customer.id : '');
  const [name, setName] = React.useState(props.customer ? props.customer.name : '');
  const [email, setEmail] = React.useState(props.customer ? props.customer.email : '');
  const [phone, setPhone] = React.useState(props.customer ? props.customer.phone : '');
  const [gst_no, setGst_no] = React.useState(props.customer ? props.customer.gst_no : '');
  const [company_name, setCompany_name] = React.useState(props.customer ? props.customer.company_name : '');
  const [address, setAddress] = React.useState(props.customer ? props.customer.address : '');
  const [city, setCity] = React.useState(props.customer ? props.customer.city : '');
  const [state, setState] = React.useState(props.customer ? props.customer.state : '');
  const [district, setDistrict] = React.useState(props.customer ? props.customer.district : '');
  const [pincode, setPincode] = React.useState(props.customer ? props.customer.pincode : '');


  const dispatch = useDispatch();

  const isCustomerAdded = useSelector(state => state.admin.isCustomersAdded);
  const isCustomerEdited = useSelector(state => state.admin.isCustomerEdited);
  const isCustomerDeleted = useSelector(state => state.admin.isCustomerDeleted);

  const handleChange = (e) => {
    e.preventDefault();
    setIsDisabled(!isDisabled);
    if (isDisabled === false) {
      dispatch(editCustomer(id, name, email, phone, gst_no, company_name, address, city, state, district, pincode));
      setIsDisabled(true);
    }
  }

  // Create a new customer
  const handleCreate = (e) => {
    e.preventDefault();
    dispatch(addCustomer(name, email, phone, gst_no, company_name, address, city, state, district, pincode));
  }

  // Delete Customer
  const handleDelete = (e) => {
    e.preventDefault();
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (confirmDelete) {
      dispatch(deleteCustomer(id));
    }
  }

  useEffect(() => {
    if (isCustomerAdded || isCustomerEdited || isCustomerDeleted) {
      onClose();
    }
  }, [isCustomerAdded, isCustomerEdited, isCustomerDeleted]);

  return (
    <>
      {modalType === "view" ? <Button p="0px" bg="transparent" onClick={onOpen}>
        <Icon as={FaEye} color="gray.400" cursor="pointer" />
      </Button> :
        <Button onClick={onOpen} style={{marginTop:"2px"}}>
          <GrAdd />
        </Button>
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {modalType === "view" ? <ModalHeader>{isDisabled ? "View" : "Edit"} {props.title}</ModalHeader> : <ModalHeader>{props.title}</ModalHeader>}
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor='name'>Name</FormLabel>
              <Input id='name' type='name' isDisabled={isDisabled} defaultValue={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='email'>Email</FormLabel>
              <Input id='email' type='email' isDisabled={isDisabled} defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='phone'>Phone</FormLabel>
              <Input id='phone' type='text' isDisabled={isDisabled} defaultValue={phone} onChange={(e) => setPhone(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='gst'>GST No.</FormLabel>
              <Input id='gst' type='text' isDisabled={isDisabled} defaultValue={gst_no} onChange={(e) => setGst_no(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='company'>Company Name</FormLabel>
              <Input id='company' type='text' isDisabled={isDisabled} defaultValue={company_name} onChange={(e) => setCompany_name(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='address'>Address</FormLabel>
              <Input id='address' type='text' isDisabled={isDisabled} defaultValue={address} onChange={(e) => setAddress(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='city'>City</FormLabel>
              <Input id='city' type='text' isDisabled={isDisabled} defaultValue={city} onChange={(e) => setCity(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='district'>District</FormLabel>
              <Input id='district' type='text' isDisabled={isDisabled} defaultValue={district} onChange={(e) => setDistrict(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='state'>State</FormLabel>
              <Input id='state' type='text' isDisabled={isDisabled} defaultValue={state} onChange={(e) => setState(e.target.value)} />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor='pincode'>Pincode</FormLabel>
              <Input id='pincode' type='text' isDisabled={isDisabled} defaultValue={pincode} onChange={(e) => setPincode(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            {props.type === "view" ?
              <Button colorScheme={isDisabled ? 'orange' : 'green'} mr={3} onClick={(e) => { handleChange(e) }}>
                {isDisabled ? "Edit" : "Save"}
              </Button>
              :
              <Button colorScheme='green' onClick={(e) => { handleCreate(e) }}>Add</Button>}
            {
              props.type === "view" ?
                <Button colorScheme='red' onClick={(e) => { dispatch(handleDelete(e)) }}>Delete</Button>
                : ""
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalCustomer