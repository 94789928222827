import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  useColorModeValue,
  Badge
} from "@chakra-ui/react";
import ModalProduct from '../Modal/ModalProduct.js';

function TableProducts({ product }) {
  const textColor = useColorModeValue("gray.700", "white");
  const colors = [
    "red",
    "orange",
    "green",
    "teal",
  ]
  const getColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  console.log(product.isAvailable);

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"24px"} w={"24px"} me="18px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            <Badge fontSize='1em' colorScheme={getColor()} variant='solid'>{product.name}</Badge>
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.price}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.hsn}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.unit}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.gst_percentage}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.stock_left}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {product.is_available ? "🟢" : "🔴"}
        </Text>
      </Td>

      <Td>
        <ModalProduct title="Product" product={product} type="view" />
      </Td>
    </Tr>
  );
}

export default TableProducts;
