import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Chakra imports
import {
  Button,
  Flex,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesCustomers from "components/Tables/TablesCustomers";
import { tablesProjectData, tablesTableData } from "variables/general";
import { loadProducts, searchProducts } from "../../actions/admin";

import ModalProduct from "../../components/Modal/ModalProduct.js";

import { FiSearch } from 'react-icons/fi'
import TableProducts from "components/Tables/TablesProducts";

function Tables(props) {

  const products = useSelector(state => state.admin.products);
  const isProductAdded = useSelector(state => state.admin.isProductAdded);
  const isProductEdited = useSelector(state => state.admin.isProductEdited);
  const isProductDeleted = useSelector(state => state.admin.isProductDeleted);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, [isProductAdded, isProductEdited, isProductDeleted]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(searchProducts(e.target.value));
  }

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        my="22px"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="row" justify="space-around" width={'100%'}>
            <Text fontSize="lg" color={textColor} fontWeight="bold" style={{ marginTop: "10px" }}>
              Products
            </Text>
            <input type="text" placeholder="Search" className="search-box" onChange={(e) => { handleSearch(e) }} />
            <FiSearch size={24} color={textColor} className="search-icon" />
            <ModalProduct type="add" title="Add Product" />
          </Flex>
        </CardHeader>
        <CardBody style={{ overflow: "scroll" }}>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400">
                  Name
                </Th>

                {/* <Th color="gray.400">Name</Th> */}
                <Th color="gray.400">Price</Th>
                <Th color="gray.400">HSN Code</Th>
                <Th color="gray.400">Unit</Th>
                <Th color="gray.400">GST Percentage</Th>
                <Th color="gray.400">Stock Left</Th>
                <Th color="gray.400">Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {products?.map((row) => {
                return (
                  <TableProducts
                    key={row._id}
                    product={row}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Tables;
