import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
// Assets
// import signInImage from "assets/img/signInImage.png";
import signInImage from "assets/img/jmt.gif";

// import actions
import { loginAdmin } from "../../actions/auth";
import { Link as ReachLink } from "react-router-dom"

function SignIn() {
  // States
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const isRegisterd = useSelector(state => state.auth.isAdminRegistered);
  const showError = useSelector(state => state.auth.err);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  // Redux states
  const dispatch = useDispatch();

  // Login admin
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginAdmin(phone, password));
  };

  if (isAuthenticated) {
    window.location.href = '/';
  }

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <>
      {isRegisterd ? <Alert status='success'>
        <AlertIcon />
        Your account has been successfully created.
      </Alert> : ""}
      {showError != null ? <Alert status='error'>
        <AlertIcon />
        {showError}
      </Alert> : ""}
      <Flex position="relative" mb="40px">
        <Flex
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="space-between"
          mb="30px"
          pt={{ sm: "100px", md: "0px" }}
        >
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "50%", lg: "42%" }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "150px", lg: "80px" }}
            >
              <Heading color={titleColor} fontSize="32px" mb="10px" >
                Welcome Back
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                Enter your phone and password to sign in
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Phone
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  placeholder="Your email adress"
                  size="lg"
                  defaultValue={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="20px"
                  fontSize="sm"
                  type="password"
                  placeholder="Your password"
                  size="lg"
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <Button
                  fontSize="15px"
                  type="submit"
                  bg="teal.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  onClick={(e) => { handleLogin(e) }}
                >
                  SIGN IN
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Don't have an account?
                  <Link
                    as={ReachLink}
                    to='/auth/signup'
                    color={titleColor} ms="5px" fontWeight="bold">
                    Sign Up
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box
            display={{ base: "none", md: "block" }}
            overflowX="hidden"
            h="100%"
            w="40vw"
            position="absolute"
            right="0px"
          >
            <Box
              bgImage={"http://156.67.218.102/v1/uploads/jmt.gif"}
              w="100%"
              h="100%"
              bgSize="cover"
              bgPosition="50%"
              position="absolute"
              borderBottomLeftRadius="20px"
            ></Box>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default SignIn;
