import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Chakra imports
import {
    Button,
    Flex,
    Spacer,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Link
} from "@chakra-ui/react";

import { Link as ReachLink } from "react-router-dom";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import { tablesProjectData, tablesTableData } from "variables/general";
import { loadQuotations, searchQuotations } from "../../actions/admin";

import ModalQuotation from "../../components/Modal/ModalQuotation.js";

import { FiSearch } from 'react-icons/fi'
import TableQuotation from "components/Tables/TableQuotation";

// icons
import { GrAdd } from "react-icons/gr";

function Quotations(props) {

    const quotations = useSelector(state => state.admin.quotations);
    const isQuotationEdited = useSelector(state => state.admin.isQuotationEdited);
    const isQuotationAdded = useSelector(state => state.admin.isQuotationAdded);
    const isQuotationDeleted = useSelector(state => state.admin.isQuotationDeleted);
    const isError = useSelector(state => state.admin.err);

    console.log(quotations);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadQuotations());
    }, [isQuotationAdded, isQuotationEdited, isQuotationDeleted]);

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(searchQuotations(e.target.value));
    }

    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card
                my="22px"
            >
                <CardHeader p="6px 0px 22px 0px">
                    <Flex direction="row" justify="space-around" width={'100%'}>
                        <Text fontSize="lg" color={textColor} fontWeight="bold" style={{ marginTop: "10px" }}>
                            Quotations
                        </Text>
                        <input type="text" placeholder="Search" className="search-box" onChange={(e) => { handleSearch(e) }} />
                        <FiSearch size={24} color={textColor} className="search-icon" />
                        {/* <ModalQuotation type="add" title="Add Quotation" /> */}
                        <Link
                            as={ReachLink}
                            to='/admin/quotations/add'
                        >
                            <Button style={{ marginTop: "2px" }}>
                                <GrAdd />
                            </Button>
                        </Link>
                    </Flex>
                </CardHeader>
                <CardBody style={{ overflow: "scroll" }}>
                    <Table variant="simple" color={textColor}>
                        <Thead>
                            <Tr my=".8rem" pl="0px">
                                <Th pl="0px" color="gray.400">
                                    Customer
                                </Th>
                                <Th color="gray.400">Bill No.</Th>
                                <Th color="gray.400">Date</Th>
                                <Th color="gray.400">Products Count</Th>
                                <Th color="gray.400">Total</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {quotations?.map((row) => {
                                return (
                                    <TableQuotation
                                        key={row._id}
                                        quotation={row}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default Quotations;
