import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import ModalCustomer from '../Modal/ModalCustomer.js';
import { getColor } from "@chakra-ui/theme-tools";

function DashboardTableRow(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const colors = [
    "red",
    "orange",
    "green",
    "teal",
  ]
  const getColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"24px"} w={"24px"} me="18px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            <Badge fontSize='1em' colorScheme={getColor()} variant='solid'>{props.name}</Badge>
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.email}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.phone}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.gst_no}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.company_name}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.address}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.city}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.district}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.state}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="normal" pb=".5rem">
          {props.pincode}
        </Text>
      </Td>

      <Td>
        <ModalCustomer title="Customer" customer={props} type="view" />
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
